<script lang="ts">
  import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
  import Icon from "fa-svelte";
  import QRious from "qrious";

  const QR = new QRious();

  export let errorCorrection = "H";
  export let background = "#fff";
  export let color = "#000";
  export let size = "1000";
  export let value = "";
  export let padding = 0;

  QR.set({
    background,
    foreground: color,
    level: errorCorrection,
    padding,
    size,
    value,
  });

  let image = QR.toDataURL("image/jpeg");
  $: {
    QR.value = value;
    image = QR.toDataURL("image/jpeg");
  }
  const download = () => {
    var a = document.createElement("a");
    a.href = image;
    a.setAttribute("download", "qr.png");
    a.click();
  };
  const downloadSVG = () => {
    //@ts-ignore
    var svg = new QRCode(value).svg();
    console.log(svg);
    gensvg(svg);
  };

  const gensvg = (svg) => {
    var svgData = svg;
    var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);
    var a = document.createElement("a");
    a.href = svgUrl;
    a.setAttribute("download", "qr.svg");
    a.click();
  };
</script>

<style>
  .save {
    padding: 0.7rem 0.3rem;
    text-align: center;

    background: #0da253;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    font-weight: bold;

    border-radius: 5px;
  }

  .container {
    display: grid;
    grid-template-rows: 1fr min-content;
    background: #ffffff;
    border-radius: 5px;
    padding: 1rem;

    border: 1px solid #f0f0f1;
    height: min-content;
  }
  .qr-container {
    display: grid;
    place-items: center;
    padding: 1rem 0;
  }
  .label {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }
  .saveContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 1rem;
  }
  .qrcode {
    width: 250px;
  }

  @media screen and (max-width: 900px) {
    .qrcode {
      width: 40%;
    }
  }
  @media screen and (max-width: 700px) {
    .qrcode {
      width: 70%;
    }
  }
  @media screen and (max-width: 400px) {
    .qrcode {
      width: 100%;
    }
  }
</style>

<div class="container">
  <div style="margin-top:10px" class="label">Here is your QRcode</div>
  <div class="qr-container"><img src={image} alt="" class="qrcode" /></div>
  <div class="saveContainer">
    <div class="save" on:click={download}>
      <Icon icon={faArrowDown} />
      PNG
    </div>
    <div class="save" on:click={downloadSVG}>
      <Icon icon={faArrowDown} />
      SVG
    </div>
  </div>
</div>
