<script lang="ts">
  import { onMount } from "svelte";
  import { domain, localKey } from "../config";
  import { makeid } from "../helpers";
  import type { Platforms } from "../types";
  import Form from "./Form.svelte";

  const randomId = `${makeid(1)}${new Date().getTime().toString(36)}`;

  let platforms: Platforms = {
    id: randomId,
    navigate: `${domain}/to/${randomId}`,
    name: "",
    uid: "local",
  };

  onMount(() => {
    const data = localStorage.getItem(localKey);
    const parsedData: Platforms = JSON.parse(data);
    platforms = { ...platforms, ...parsedData };
  });
</script>

<Form {platforms} isLocal={true} platformsList={null} addNew={null} getStats={null} getList={null} />
