export const firebaseConfig = {
    apiKey: "AIzaSyDVx1As4X0EymaYbKOoX15lpGnoj2-ALT0",
    authDomain: "lds-qr.firebaseapp.com",
    databaseURL: "https://lds-qr.firebaseio.com",
    projectId: "lds-qr",
    storageBucket: "lds-qr.appspot.com",
    messagingSenderId: "578456657841",
    appId: "1:578456657841:web:c81bccdc545b69ea6b2790",
    measurementId: "G-50XL5F1QBG",
};
export const domain = "https://lds-qr.web.app";
export const localKey = "non-auth";
