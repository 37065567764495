export function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export const mapObjectToArray = (obj) => {
    const mappedDatas = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            mappedDatas.push(Object.assign(Object.assign({}, obj[key]), { id: key }));
        }
    }
    return mappedDatas;
};
export function formatDate(unix) {
    let date = new Date(unix).toISOString();
    return date.substr(0, date.indexOf("T"));
}
export function getLastNDay(n) {
    return Array(n) // Create empty array of specified length, here a week.
        .fill(new Date()) // Fill it with dates of your choice, here today.
        .map((today, i) => today - 8.64e7 * i) // Subtract days worth of time times the index
        .map((day) => formatDate(day))
        .reverse();
}
export function getTotal(stats) {
    let ios = 0;
    let android = 0;
    let huawei = 0;
    let ipad = 0;
    let web = 0;
    Object.keys(stats).map((os) => {
        if (os === "ios")
            Object.keys(stats[os]).map((date) => (ios += stats[os][date]));
        if (os === "android")
            Object.keys(stats[os]).map((date) => (android += stats[os][date]));
        if (os === "huawei")
            Object.keys(stats[os]).map((date) => (huawei += stats[os][date]));
        if (os === "ipad")
            Object.keys(stats[os]).map((date) => (ipad += stats[os][date]));
        if (os === "web")
            Object.keys(stats[os]).map((date) => (web += stats[os][date]));
    });
    let csv = [["Total", "Date", "iPhone", "iPad", "Android", "Huawei", "Web"]];
    Object.keys(stats).map((os) => {
        Object.keys(stats[os]).map((date) => {
            let index = csv.findIndex((x) => x[0] === date);
            if (index !== -1) {
                csv[index][getCSVIndex(os)] = stats[os][date];
            }
            else {
                let newArr = [0, date, 0, 0, 0, 0, 0];
                newArr[getCSVIndex(os)] = stats[os][date];
                csv.push(newArr);
            }
        });
    });
    csv = csv.map((a, i) => {
        let total = Number(a[2]) + Number(a[3]) + Number(a[4]) + Number(a[5]) + Number(+a[6]);
        if (i === 0) {
            total = "Total";
        }
        return [total, a[1], a[2], a[3], a[4], a[5], a[6]];
    });
    return {
        ios,
        android,
        huawei,
        ipad,
        web,
        total: ios + android + huawei + ipad + web,
        csv,
    };
}
function getCSVIndex(os) {
    switch (os) {
        case "ios":
            return 2;
        case "ipad":
            return 3;
        case "android":
            return 4;
        case "huawei":
            return 5;
        case "web":
            return 6;
        default:
            return null;
    }
}
export function downloadCSV(rows) {
    let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `qr-generator.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
}
export const getOptions = (series) => ({
    series,
    theme: {
        mode: "light",
        palette: "palette1",
    },
    chart: {
        height: 350,
        fontFamily: "Poppins",
        type: "bar",
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
        animations: {
            enabled: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        // curve: "line",
        width: 2,
    },
    yaxis: {
        // tickAmount: 4,
        labels: {
            formatter: function (val) {
                return Number(val);
            },
        },
    },
    fill: {
        opacity: 1,
        background: "#fff",
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + " users";
            },
        },
    },
    legend: {
        show: false,
        onItemClick: {
            toggleDataSeries: false,
        },
        onItemHover: {
            highlightDataSeries: false,
        },
    },
});
export function getColor(os) {
    switch (os) {
        case "ios":
            return "#4DC3FD";
        case "android":
            return "#45BF26";
        case "huawei":
            return "#FF6220";
        case "ipad":
            return "#0B4AAA";
        case "web":
            return "#A427FD";
        default:
            return "#D8D8D8";
    }
}
