<script lang="ts">
  import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
  import ApexCharts from "apexcharts";
  import Icon from "fa-svelte";
  import { onMount } from "svelte";
  import { domain } from "../config";
  import { db } from "../firebase";
  import {
    downloadCSV,
    getColor,
    getLastNDay,
    getOptions,
    getTotal,
    makeid,
    mapObjectToArray,
  } from "../helpers";
  import { user } from "../store";
  import type { Platforms, Stats } from "../types";
  import Form from "./Form.svelte";

  type ReturnStats = {
    ios: number;
    android: number;
    huawei: number;
    ipad: number;
    web: number;
    total: number;
    csv: (string | number)[][];
  };
  let platformsList: Platforms[] = [];
  let selected: Platforms;
  let stats: Stats;
  let totalStats: ReturnStats;

  onMount(() => getList());
  const getList = () => {
    db.ref("apps/")
      .orderByChild("uid")
      .equalTo($user.uid)
      .once(
        "value",
        (snapshot) => {
          platformsList = mapObjectToArray(snapshot.val());
          platformsList.sort((a: Platforms, b: Platforms) => {
            return (
              new Date(b.dateSaved).getTime() - new Date(a.dateSaved).getTime()
            );
          });
          if (platformsList.length) {
            selected = platformsList[0];
          } else {
            addNew();
          }
        },
        (err) => console.log(err)
      );
  };
  const getStats = (appId: string) => {
    db.ref("stats/" + appId).once(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          stats = snapshot.val().daily as Stats;

          totalStats = getTotal(stats);
          const osarr = [
            { label: "iPhone", value: "ios" },
            { label: "iPad", value: "ipad" },
            { label: "Android", value: "android" },
            { label: "Huawei", value: "huawei" },
            { label: "Web", value: "web" },
          ];
          let series = osarr.map((os) => ({
            name: os.label,
            data: getLastNDay(5).map((date) => ({
              x: date,
              y: stats[os.value] ? stats[os.value][date] || 0 : 0,
            })),
            color: getColor(os.value),
          }));
          const options = getOptions(series);
          setTimeout(() => {
            var chart = new ApexCharts(
              document.querySelector("#chart"),
              options
            );
            chart.render();
          }, 100);
        } else {
          stats = null;
          totalStats = null;
        }
      },
      (err) => console.log(err)
    );
  };

  const addNew = () => {
    const randomId = `${makeid(1)}${new Date().getTime().toString(36)}`;
    let data: Platforms = {
      id: randomId,
      navigate: `${domain}/to/${randomId}`,
      name: "",
      uid: $user.uid,
    };
    platformsList = [data, ...platformsList];
    selected = data;
  };

  const download = () => {
    downloadCSV(totalStats.csv);
  };
</script>

<style>
  .chartContainer {
    display: grid;
    grid-template-columns: 1fr 418px;
    gap: 5rem;
    background: white;
    border: 1px solid #f0f0f1;
    border-radius: 5px;
    margin-top: 1rem;
    padding: 2rem;
  }
  .label {
    font-size: 0.85rem;
    margin: 15px 0;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 0.7;
  }
  .ml-auto {
    margin-left: auto;
  }
  .platformContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.85rem;
    font-weight: 600;
    opacity: 0.8;
  }
  .save {
    color: #0da253;
    cursor: pointer;
    margin-top: 25px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: tomato;
    margin-right: 7px;
  }
  @media screen and (max-width: 900px) {
    .chartContainer {
      grid-template-columns: 1fr;
    }
  }
</style>

{#if selected}
  <Form
    platforms={selected}
    isLocal={false}
    {platformsList}
    {addNew}
    {getList}
    {getStats} />
  {#if stats}
    <div class="chartContainer">
      <div id="chart" />
      <div>
        <div class="label">Total Stats</div>

        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('total')}`} />
          <div class="platformLabel">Total Clicks</div>
          <div class="ml-auto platform">{totalStats?.total || 0}</div>
        </div>

        <div class="label">Per device</div>
        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('ios')}`} />
          <div class="platformLabel">iPhone</div>
          <div class="ml-auto platform">{totalStats?.ios || 0}</div>
        </div>

        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('ipad')}`} />
          <div class="platformLabel">iPad</div>
          <div class="ml-auto platform">{totalStats?.ipad || 0}</div>
        </div>

        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('android')}`} />
          <div class="platformLabel">Android</div>
          <div class="ml-auto platform">{totalStats?.android || 0}</div>
        </div>
        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('huawei')}`} />
          <div class="platformLabel">Huawei</div>
          <div class="ml-auto platform">{totalStats?.huawei || 0}</div>
        </div>

        <div class="platformContainer">
          <div class="circle" style={`background:${getColor('web')}`} />
          <div class="platformLabel">Web</div>
          <div class="ml-auto platform">{totalStats?.web || 0}</div>
        </div>
        <div class="platformContainer save">
          <div class="ml-auto" on:click={download}>
            <Icon icon={faArrowDown} />
            Download CSV
          </div>
        </div>
      </div>
    </div>
    <div style="height:50px" />
  {/if}
{/if}
