<script lang="ts">
  import { faGoogle } from "@fortawesome/free-brands-svg-icons";
  import Icon from "fa-svelte";
  import { Link } from "svelte-routing";
  import { localKey } from "../config";
  import { auth, db, provider } from "../firebase";
  import { user } from "../store";
  import type { Platforms } from "../types";
  import Logo from "./Logo.svelte";

  export let active: string;
  const handleGoogleLogin = () => {
    auth
      .signInWithPopup(provider)
      .then((result) => {
        var firebaseuser = result.user;

        if (firebaseuser) {
          let { email, uid } = firebaseuser;

          const data = localStorage.getItem(localKey);

          if (data) {
            let platforms: Platforms = JSON.parse(data);

            db.ref(`apps/${platforms.id}`).set({ ...platforms, uid }, (err) => {
              if (!err) {
                localStorage.removeItem(localKey);
              }
            });
          } else {
            user.set({ loggedIn: true, email, uid });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleLogOut = () => {
    auth.signOut().then(
      () => {
        // Sign-out successful.
        user.set({ loggedIn: false, email: "", uid: "" });
      },
      (error) => {
        // An error happened.
        console.warn("error on logout", error.message);
      }
    );
  };
</script>

<style>
  .header {
    background: #ffffff;
    border-bottom: 1px solid #e4e5e7;
    margin-bottom: 1rem;
  }
  .header__inner {
    background: #ffffff;
    margin: 0 auto;

    max-width: 1200px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  .header__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 30px;
    width: 165px;
  }
  .header__login {
    padding: 0.7rem 1.3rem;
    padding-left: 8px;
    background: #4285f4;
    color: white;
    font-size: 0.87rem;

    font-weight: bold;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .header__loginGoogle {
    /* margin-left: auto; */
    border: 6px solid #4285f4;
    height: 50px;
    width: 50px;
    font-size: 1.2rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4285f4;
    cursor: pointer;
  }
  .header_button {
    padding: 1.7rem 1rem;

    text-transform: uppercase;
    font-size: 0.87rem;
    color: #282d34;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
  .header_button__active {
    color: #0da253;

    border-bottom: 1px solid #0da253;
    cursor: pointer;
  }
  .header__loginMail {
    font-size: 0.8rem;
    opacity: 0.6;
    font-weight: bold;
    margin-right: 1rem;
  }
  .header__signout {
    font-size: 0.83rem;
    margin-right: 1rem;
    color: #0da253;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .ml-auto {
    margin-left: auto;
  }
  .header__signoutcontainer {
    display: flex;
  }
  @media screen and (max-width: 769px) {
    .header__inner {
      padding: 1rem;
    }
    .header_button {
      display: none;
    }
    .header_button__active {
      display: none;
    }
    .header__title {
      font-size: 1rem;
      width: 30%;
      min-width: 100px;
    }
    .header__login {
      display: none;
    }
    .header__signoutcontainer {
      display: block;
    }
  }
</style>

<div class="header">
  <div class="header__inner">
    <div class="header__title">
      <Logo />
    </div>
    <Link to="/">
      <div class={`header_button  ${active === 'home' && 'header_button__active'}`}>Generator</div>
    </Link>
    <Link to="/about">
      <div class={`header_button  ${active === 'about' && 'header_button__active'}`}>About</div>
    </Link>
    <!-- <div class="header_button">NEWS</div> -->
    {#if $user.loggedIn && $user.uid}
      <div class="header__signoutcontainer ml-auto">
        <div class="header__loginMail">{$user.email}</div>
        <div class="header__signout" on:click={handleLogOut}>Sign out</div>
      </div>
    {:else}
      <div on:click={handleGoogleLogin} class="header__loginGoogle ml-auto">
        <Icon icon={faGoogle} />
      </div>
      <div class="header__login" on:click={handleGoogleLogin}>Sign in with Google</div>
    {/if}
  </div>
</div>
