<script lang="ts">
  import { Route, Router } from "svelte-routing";
  import About from "./Components/About.svelte";
  import Home from "./Components/Home.svelte";
  import Navigator from "./Components/Navigator.svelte";
</script>

<Router>
  <Route path="to/:id" let:params>
    <Navigator to={params.id} />
  </Route>
  <Route path="/">
    <Home />
  </Route>
  <Route path="/about">
    <About />
  </Route>
  <Route component={Home} />
</Router>
