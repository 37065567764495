<script lang="ts">
  import { onMount } from "svelte";
  import { db } from "../firebase";
  import type { Platforms } from "../types";

  export let to: string;

  onMount(() => {
    db.ref("apps/")
      .orderByChild("id")
      .equalTo(to)
      .once("value", (snapshot) => {
        const value: Platforms = snapshot.val()[to];
        const now = new Date().toISOString();

        if (value) {
          const platform = getPlatform();
          db.ref(`stats/${to}/clickCount`).transaction((currentClicks) => {
            return (currentClicks || 0) + 1;
          });
          db.ref(`stats/${to}/lastUsed`).set(now);
          db.ref(
            `stats/${to}/daily/${platform}/${now.substr(0, now.indexOf("T"))}`
          )
            .transaction((currentClicks) => {
              return (currentClicks || 0) + 1;
            })
            .then(() => {
              window.location.href = value[platform] || value.web;
              // window.close();
            });
          //
        } else alert("invalid url");
      });
  });

  const getPlatform = (): "ios" | "android" | "ipad" | "huawei" | "web" => {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent) && checkHuawei(navigator.userAgent)) {
      return "huawei";
    } else if (
      /android/i.test(userAgent) &&
      navigator.userAgent.includes("Chrome")
    ) {
      return "android";
    } else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    } else if (/iPad/.test(userAgent) && !window.MSStream) {
      return "ipad";
    } else {
      return "web";
    }
  };

  const checkHuawei = (userAgent: string): boolean => {
    if (userAgent.includes("HMSCore")) {
      return true;
    } else if (userAgent.includes("JNY-LX1")) {
      return true;
    } else if (userAgent.includes("LIO-L29")) {
      return true;
    } else if (userAgent.includes("ANA-NX9")) {
      return true;
    } else if (userAgent.includes("ELS-NX9")) {
      return true;
    } else if (userAgent.includes("ART-L29")) {
      return true;
    } else if (userAgent.includes("AQM-LX1")) {
      return true;
    } else if (userAgent.includes("MED-LX9N")) {
      return true;
    } else if (userAgent.includes("DRA-LX9")) {
      return true;
    } else if (userAgent.includes("Tahiti-N29DX")) {
      return true;
    } else {
      return false;
    }
  };
</script>
