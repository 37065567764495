<script lang="ts">
  import { NotificationDisplay } from "@beyonk/svelte-notifications";
  import { onMount } from "svelte";
  import { auth } from "../firebase";
  import { user } from "../store";
  import Auth from "./Auth.svelte";
  import Header from "./Header.svelte";
  import NonAuth from "./NonAuth.svelte";

  let state = { loading: true };
  onMount(async () => {
    auth.onAuthStateChanged((authUser) => {
      state = { loading: false };
      if (authUser) {
        user.set({ ...$user, loggedIn: true, email: authUser.email, uid: authUser.uid });
      }
    });
  });
</script>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background: #f7f7f8;
  }
  :global(*) {
    box-sizing: border-box;
  }
  :global(a:hover) {
    text-decoration: none;
  }
  main {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 1rem;
  }
  .footer {
    display: none;
  }
  @media screen and (max-width: 769px) {
    .footer {
      display: grid;
      background: #292929;
      padding: 1rem;
      gap: 1rem;
      /* padding-bottom: 45%; */
    }
    .footer > li {
      font-size: 0.8rem;
      opacity: 0.6;
      font-weight: bold;
      color: white;
    }
  }
</style>

<Header active="home" />
<NotificationDisplay />
<main>
  {#if !state.loading}
    {#if $user.loggedIn && $user.uid}
      <Auth />
    {:else}
      <NonAuth />
    {/if}
  {/if}
</main>
{#if !state.loading}
  <div class="footer">
    <li>ABOUT</li>
    <!-- <li>NEWS</li> -->
  </div>
{/if}
