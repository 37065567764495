import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
var firebaseConfig = {
    apiKey: FIREBASE_APIKEY,
    authDomain: "lds-qr.firebaseapp.com",
    databaseURL: "https://lds-qr.firebaseio.com",
    projectId: "lds-qr",
    storageBucket: "lds-qr.appspot.com",
    messagingSenderId: "578456657841",
    appId: "1:578456657841:web:c81bccdc545b69ea6b2790",
    measurementId: "G-50XL5F1QBG",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.database();
export const provider = new firebase.auth.GoogleAuthProvider();
