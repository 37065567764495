<script lang="ts">
  import { notifier } from "@beyonk/svelte-notifications";
  import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
  import { faGlobe, faPlus } from "@fortawesome/free-solid-svg-icons";
  import Icon from "fa-svelte";
  import { domain, localKey } from "../config";
  import { db } from "../firebase";
  import { makeid } from "../helpers";
  import type { Platforms } from "../types";
  import Huawei from "./Huawei.svelte";
  import QrCode from "./QrCode.svelte";

  export let platforms: Platforms;
  export let platformsList: Platforms[];
  export let isLocal: boolean;
  export let getList: () => void;
  export let getStats: (appId: string) => void;
  export let addNew: () => void;

  const handleForm = () => {
    if (platforms.name) {
      let data = platforms;
      data.dateSaved = new Date().toISOString();
      if (isLocal) {
        localStorage.setItem(localKey, JSON.stringify(data));
        db.ref("apps/" + data.id).set(data, (err) => {
          if (!err) notifier.success(`${data.name} successfully saved!`);
          platforms = data;
        });
      } else {
        db.ref("apps/" + data.id).set(data, (err) => {
          if (!err) {
            notifier.success(`${data.name} successfully saved!`);
            getList();
          }
        });
      }
    }
  };

  const remove = () => {
    if (platforms.name) {
      db.ref("apps/" + platforms.id).remove((err) => {
        if (!err) {
          notifier.success(`${platforms.name} successfully deleted!`);

          if (isLocal) {
            localStorage.removeItem(localKey);
            const randomId = `${makeid(3)}${new Date().getTime().toString(36)}`;

            platforms = {
              id: randomId,
              navigate: `${domain}/to/${randomId}`,
              name: "",
              uid: "local",
            };
          } else {
            getList();
          }
        }
      });
      db.ref("stats/" + platforms.id).remove();
    }
  };
  $: {
    if (platforms?.id && getStats) {
      getStats(platforms.id);
    }
  }
</script>

<style>
  .content {
    display: grid;
    height: min-content;
    margin-bottom: 1rem;
    grid-area: content;
  }
  .saveButton {
    padding: 0.5rem 0.7rem;
    margin: 0;
    margin-left: 1rem;
    border: 2px solid #0da253;
    border-radius: 5px;
    font-size: 0.87rem;
    color: #0da253;
    cursor: pointer;
    width: max-content;
    font-weight: bold;
  }
  .removeButton {
    padding: 0.5rem 0.3rem;
    margin: 0;

    color: tomato;
    cursor: pointer;
    width: max-content;
    border: unset;
    background: transparent;
    font-weight: bold;
    opacity: 0.8;
    font-size: 0.79rem;
  }
  .addButton {
    padding: 0.8rem 1rem;
    margin: 0;
    margin-left: auto;
    background: #0da253;
    color: white;
    cursor: pointer;
    width: max-content;
    border: unset;
    border-radius: 7px;
    font-size: 0.87rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .label {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }
  .saveLabel {
    font-size: 0.85rem;
    background: #ffffff;
    border-radius: 5px;
    padding: 5px 1rem;
    margin-top: 0.5rem;
    border: 1px solid #f0f0f1;
    display: flex;
    align-items: center;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 450px;
    grid-template-areas:
      "content selector"
      "content qrcode"
      "stats qrcode"
      ". qrcode";

    gap: 1rem;
  }
  .formContainer {
    display: grid;
    grid-template-columns: 40px 1fr;

    padding: 0.6rem 0.4rem;

    align-items: center;
    background: white;
    border: 1px solid #f0f0f1;
    border-radius: 5px;
    margin-bottom: 0.95rem;
  }
  .marketIcon {
    font-size: 1.5rem;
    color: #8f939a;
    display: grid;
    place-items: center;
  }
  .formContainer > input {
    margin: 0;
    padding: 0;
    border: unset;
    font-size: 0.87rem;
  }
  .formContainer > input:focus {
    outline: none;
  }
  .nameInput {
    padding: 0.65rem 1rem;
    border-radius: 5px;
    align-items: center;
    background: white;
    border: 1px solid #f0f0f1;
    margin-bottom: 15px;
    font-size: 0.87rem;
  }
  .nameInput:focus {
    outline: none;
  }
  .selector {
    grid-area: selector;
    background: #ffffff;
    border-radius: 5px;
    padding: 1rem;

    border: 1px solid #f0f0f1;
    display: grid;
  }
  .selector > input {
    display: block;
    padding: 0.5rem 0.4rem;
    align-items: center;
    background: white;
    border: 1px solid #f0f0f1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  .selector > input:focus {
    outline: none;
  }
  .selector > select {
    display: block;
    padding: 0.5rem 0.4rem;
    align-items: center;
    background: white;
    border: 1px solid #f0f0f1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  .selector > select:focus {
    outline: none;
  }
  .submitContainer {
    display: flex;
  }
  .qrcode {
    grid-area: qrcode;
  }
  .stats {
    display: none;
    grid-area: stats;
  }
  .ml-auto {
    margin-left: auto;
  }
  @media screen and (max-width: 900px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "selector"
        "content"
        "qrcode"
        "stats";
      margin-bottom: 30px;
    }
  }
</style>

<div class="container">
  <form class="content" on:submit|preventDefault={handleForm}>
    <div class="label">What is the name of your application?</div>
    <input class="nameInput" id="name" type="text" required placeholder="Application Name" bind:value={platforms.name} />
    <div class="label">Add all URLs to your app</div>
    <div class="formContainer">
      <div class="marketIcon">
        <Icon icon={faApple} />
      </div>
      <input id="ios" type="url" bind:value={platforms.ios} placeholder="URL to your app on Appstore (iOS)" />
    </div>
    <div class="formContainer">
      <div class="marketIcon">
        <Icon icon={faApple} />
      </div>
      <input id="ipad" type="url" bind:value={platforms.ipad} placeholder="URL to your iPad only version" />
    </div>
    <div class="formContainer">
      <div class="marketIcon">
        <Icon icon={faAndroid} />
      </div>
      <input id="android" type="url" bind:value={platforms.android} placeholder="URL to your app on Google Play" />
    </div>
    <div class="formContainer">
      <div class="marketIcon">
        <Huawei />
      </div>
      <input id="huawei" placeholder="URL to your app on App Gallery" type="url" bind:value={platforms.huawei} />
    </div>
    <div class="formContainer">
      <div class="marketIcon">
        <Icon icon={faGlobe} />
      </div>
      <input
        id="web"
        required
        placeholder="Fallback URL for any other any other device/browser"
        type="url"
        bind:value={platforms.web} />
    </div>
    <div class="submitContainer">
      <div class="ml-auto" />
      <button type="button" class="removeButton" on:click={remove}>DELETE</button>
      <button type="submit" class="saveButton">{platforms.dateSaved ? 'UPDATE' : 'SAVE'}</button>
    </div>
  </form>
  <div class="stats">
    {#if platforms.dateSaved}
      <div class="saveLabel">Date saved: {new Date(platforms.dateSaved).toLocaleString()}</div>
    {/if}
  </div>
  {#if platforms.dateSaved || platformsList}
    <div class="selector">
      {#if platforms.dateSaved}
        <div class="label">Shortened Project’s web address</div>
        <input type="text" readonly bind:value={platforms.navigate} inputmode="none" />
      {/if}
      {#if platformsList}
        <div style="margin-top:10px" class="label">Your current applications</div>
        {#if platformsList.length}
          <select bind:value={platforms}>
            {#each platformsList as d}
              <option value={d}>{d.name}</option>
            {/each}
          </select>
        {:else}
          <div class="noProjectLabel">No project</div>
        {/if}
        <div style="display:flex">
          <button class="addButton" on:click={platforms.dateSaved ? addNew : null}>
            <Icon icon={faPlus} />
            ADD NEW</button>
        </div>
      {/if}
    </div>
  {/if}
  <div class="qrcode">
    {#if platforms.dateSaved}
      <QrCode value={platforms.navigate} />
    {/if}
  </div>
</div>
